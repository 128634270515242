<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <v-container fluid>
        <v-card raised class="mx-auto pa-5 card-custom-rounded-10 mb-7">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1"
                >Ubah Password</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-container fluid>
            <v-form
              id="change-password-form"
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="onSubmit()"
              class="mt-4"
            >
              <v-container fluid>
                <v-alert
                  type="error"
                  v-if="errorMessage"
                  dismissible
                  @input="errorMessage = null"
                >
                  <div v-if="Array.isArray(errorMessage)">
                    <ul>
                      <li
                        v-for="(msg, index) in errorMessage"
                        v-bind:key="index"
                      >
                        {{ msg }}
                      </li>
                    </ul>
                  </div>
                  <div v-else-if="typeof errorMessage === 'object'">
                    <div
                      class="mb-2"
                      v-for="(object, key) in errorMessage"
                      v-bind:key="key"
                    >
                      <p class="ma-0">{{ key | snakeToTitle }}</p>
                      <ul>
                        <li v-for="(list, index) in object" v-bind:key="index">
                          {{ list }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p class="ma-0" v-else>{{ errorMessage }}</p>
                </v-alert>
                <v-row>
                  <v-col cols="12" sm="12" class="py-0">
                    <v-text-field
                      v-model="dataUser.username"
                      prepend-icon="person"
                      label="Username"
                      readonly
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" class="py-0">
                    <v-text-field
                      v-model="dataUser.name"
                      prepend-icon="person"
                      label="Name"
                      readonly
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" class="py-0">
                    <v-text-field
                      v-model="dataPassword.oldPassword"
                      :rules="oldPasswordRules"
                      prepend-icon="lock"
                      label="Masukan Password Lama"
                      :append-icon="e0 ? 'visibility' : 'visibility_off'"
                      @click:append="() => (e0 = !e0)"
                      :type="e0 ? 'password' : 'text'"
                      counter
                      :loading="loadingOldPassword"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" class="py-0">
                    <v-text-field
                      v-model="dataPassword.newPassword"
                      :rules="newPasswordRules"
                      prepend-icon="lock"
                      label="Masukan Password Baru"
                      :append-icon="e1 ? 'visibility' : 'visibility_off'"
                      @click:append="() => (e1 = !e1)"
                      :type="e1 ? 'password' : 'text'"
                      counter
                      :loading="loadingNewPassword"
                    />

                    <v-text-field
                      v-model="dataPassword.newPasswordConfirm"
                      :rules="newPasswordConfirmRules"
                      prepend-icon="lock"
                      label="Masukan Konfirmasi Password Baru"
                      :append-icon="e2 ? 'visibility' : 'visibility_off'"
                      @click:append="() => (e2 = !e2)"
                      :type="e2 ? 'password' : 'text'"
                      counter
                      required
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-10">
                  <v-btn
                    :loading="formLoading"
                    @click="$router.back()"
                    color="default"
                    class="px-10 mr-5"
                  >
                    <v-icon left>keyboard_arrow_left</v-icon>
                    BACK
                  </v-btn>
                  <v-btn
                    :loading="formLoading"
                    :disabled="!valid"
                    type="submit"
                    color="primary"
                    class="px-10"
                    form="change-password-form"
                  >
                    SUBMIT
                    <v-icon right>send</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-container>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import AuthService from "@/services/resources/auth.service";

export default {
  data() {
    return {
      valid: false,
      formLoading: false,
      loadingOldPassword: false,
      loadingNewPassword: false,
      passwordOldError: "",
      passwordNewError: "",

      errorMessage: null,

      e0: true,
      e1: true,
      e2: true,

      // Form
      dataPassword: {
        oldPassword: null,
        newPassword: null,
        newPasswordConfirm: null
      },
      dataUser: {
        id: null,
        username: null,
        name: null
      },

      // Rules
      oldPasswordRules: [v => !!v || "Password Lama harus diisi"],
      newPasswordRules: [
        v => !!v || "Password Baru harus diisi"
        // v => /[a-z]/g.test(v) || "Password harus mengandung Lowercase",
        // v => /[A-Z]/g.test(v) || "Password harus mengandung Uppercase",
        // v => /[0-9]/g.test(v) || "Password harus mengandung Nomor",
        // v => (v && v.length > 8) || "Password harus lebih dari 8 digit"
      ],
      newPasswordConfirmRules: [
        v => !!v || "Konfirmasi Password Baru harus diisi",
        v =>
          (v && v == this.dataPassword.newPassword) || "Password harus sesuai"
      ]
    };
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: "Konfirmasi",
          message: `Apakah anda yakin akan merubah password?`,
          button: {
            no: "Batal",
            yes: "Ya"
          },
          callback: confirm => {
            if (confirm) {
              this.updatePassword({
                id: this.dataUser.id,
                password_old: this.dataPassword.oldPassword,
                password_new: this.dataPassword.newPassword,
                password_confirm: this.dataPassword.newPasswordConfirm
              });
            }
          }
        });
      } else {
        this.$refs.form.inputs
          .filter(d => d.errorBucket.length > 0)[0]
          .$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest"
          });
      }
    },
    // Service
    async updatePassword(payload) {
      this.formLoading = true;
      try {
        await AuthService.changePassword(payload)
          .then(response => {
            const { status, message, error } = response.data;
            if (status) {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
              this.$router.back();
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error",
                timeout: 10000
              });
              this.errorMessage = error;
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: "Password Anda gagal dirubah",
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getCurrentUser() {
      try {
        this.loading = true;
        await AuthService.getProfile()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.$_.merge(this.dataUser, data);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getCurrentUser();
    });
  }
};
</script>
